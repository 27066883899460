html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fdff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #adb3bd;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a9a9a9;
}

::-webkit-scrollbar-corner {
  background-color: #f0f0f0;
}

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
