.sales-info-container {
  flex: 1;
  display: grid;
  grid-template-areas:
    "sales-rep remarks"
    "sales-details remarks";
  grid-template-columns: 1fr 2.5fr;
  grid-template-rows: auto 1fr;
  gap: 20px;
  margin: -1.8rem;
  padding: 1.8rem;
  min-height: 0;
}

.assigned-sales-rep {
  grid-area: sales-rep;
}

.sales-details {
  grid-area: sales-details;
  min-height: 0;
  background-color: white;
  padding: 1.2rem 1.5rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.interaction-remarks {
  grid-area: remarks;
  overflow: auto;
}

.sales-details-field-container {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}
