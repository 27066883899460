.main-grid-container {
  display: grid;
  grid-template-columns: 3.5rem auto; /* Sidebar width + remaining space */
  grid-template-rows: 3.5rem auto; /* Top nav bar height + remaining space */
  min-height: 100%; /* Full viewport height */
  overflow: hidden; /* Hide overflow when sidebar overlaps */
}

.sidebar {
  grid-column: 1;
  grid-row: 1 / span 2; /* Sidebar spans both rows */
  z-index: 1000;
}

.top-nav {
  grid-column: 2;
  background-color: #444;
  color: #fff;
  z-index: 1;
}

.main-content {
  grid-column: 2;
  grid-row: 2;
  padding: 1.8rem;
  height: calc(100dvh - 3.5rem - 3.6rem);
  overflow-y: auto;
}
